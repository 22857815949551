import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { filter, tap } from 'rxjs';
import { ITransport } from 'src/app/repository/transport/transport.model';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-transport-route-select',
  templateUrl: './transport-route-select.component.html',
  styleUrls: ['./transport-route-select.component.scss'],
})
export class TransportRouteSelectComponent implements OnInit {
  transportList: ITransport[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public matDialogData: any,
    private matDialogRef: MatDialogRef<TransportRouteSelectComponent>,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.transportList = this.matDialogData.transportList;
  }

  getTm(tm: string, min: number): string {
    return dayjs(`1111-01-01 ${tm}`).add(min, 'minute').format('HH:mm');
  }

  onNoBusClick(): void {
    this.dialogService
      .confirm('本当にバスを利用しませんか？')
      .pipe(
        filter((answer) => answer === 'right'),
        tap(() => this.matDialogRef.close({}))
      )
      .subscribe();
  }
}
