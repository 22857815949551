import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { GakuyuLayoutComponent } from './components/gakuyu-layout/gakuyu-layout.component';
import { GakuyuMobileLayoutComponent } from './components/gakuyu-mobile-layout/gakuyu-mobile-layout.component';
import { ScreenSizeGuard } from './core/screen-size.guard';

const desktopRouteList: Routes = [
  {
    path: '',
    component: GakuyuLayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./components/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'intro',
        loadChildren: () =>
          import('./components/intro/intro.module').then((m) => m.IntroModule),
      },
      {
        path: 'lesson',
        loadChildren: () =>
          import('./components/lesson-list/lesson-list.module').then(
            (m) => m.LessonListModule
          ),
      },
      {
        path: 'lesson/:id',
        loadChildren: () =>
          import('./components/lesson-detail/lesson-detail.module').then(
            (m) => m.LessonDetailModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'pricing',
        loadChildren: () =>
          import('./components/pricing/pricing.module').then(
            (m) => m.PricingModule
          ),
      },
      {
        path: 'faq',
        loadChildren: () =>
          import('./components/faq/faq.module').then((m) => m.FaqModule),
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('./components/contact/contact.module').then(
            (m) => m.ContactModule
          ),
      },
      {
        path: 'terms',
        data: { type: 'terms', noButton: true },
        loadChildren: () =>
          import(
            './components/user-info/user-info-terms/user-info-terms.module'
          ).then((m) => m.UserInfoTermsModule),
      },
      {
        path: 'privacy',
        data: { type: 'privacy', noButton: true },
        loadChildren: () =>
          import(
            './components/user-info/user-info-terms/user-info-terms.module'
          ).then((m) => m.UserInfoTermsModule),
      },
      {
        path: 'register/terms',
        data: { type: 'terms' },
        loadChildren: () =>
          import(
            './components/user-info/user-info-terms/user-info-terms.module'
          ).then((m) => m.UserInfoTermsModule),
      },
      {
        path: 'register/privacy',
        data: { type: 'privacy' },
        loadChildren: () =>
          import(
            './components/user-info/user-info-terms/user-info-terms.module'
          ).then((m) => m.UserInfoTermsModule),
      },
      {
        path: 'register',
        loadChildren: () =>
          import('./components/register/register.module').then(
            (m) => m.RegisterModule
          ),
      },
      {
        path: 'my',
        loadChildren: () =>
          import('./components/my/my.module').then((m) => m.MyModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'my/basic',
        loadChildren: () =>
          import(
            './components/user-info/user-info-basic/user-info-basic.module'
          ).then((m) => m.UserInfoBasicModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'my/privacy',
        loadChildren: () =>
          import(
            './components/user-info/user-info-privacy/user-info-privacy.module'
          ).then((m) => m.UserInfoPrivacyModule),
        canActivate: [AuthGuard],
      },
      // {
      //   path: 'my/booking',
      //   loadChildren: () =>
      //     import(
      //       './components/user-info/user-info-booking/user-info-booking.module'
      //     ).then((m) => m.UserInfoBookingModule),
      //   canActivate: [AuthGuard],
      // },
      {
        path: 'my/payment',
        loadChildren: () =>
          import(
            './components/user-info/user-info-payment/user-info-payment.module'
          ).then((m) => m.UserInfoPaymentModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'my/subscription',
        loadChildren: () =>
          import(
            './components/user-info/user-subscription/user-subscription.module'
          ).then((m) => m.UserSubscriptionModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'my/guide',
        loadChildren: () =>
          import(
            './components/user-info/user-info-guide/user-info-guide.module'
          ).then((m) => m.UserInfoGuideModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'news',
        loadChildren: () =>
          import('./components/news/news.module').then((m) => m.NewsModule),
      },
      {
        path: 'news/:id',
        loadChildren: () =>
          import('./components/news-detail/news-detail.module').then(
            (m) => m.NewsDetailModule
          ),
      },
      {
        path: 'tokutei',
        loadChildren: () =>
          import('./components/tokutei/tokutei.module').then(
            (m) => m.TokuteiModule
          ),
      },
      { path: '', pathMatch: 'full', redirectTo: '/home' },
    ],
  },
];

const mobileRouteList: Routes = [
  {
    path: '',
    component: GakuyuMobileLayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./components/membership/membership.module').then(
            (m) => m.MembershipModule
          ),
      },
      {
        path: 'my',
        loadChildren: () =>
          import('./components/my/my.module').then((m) => m.MyModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'news',
        loadChildren: () =>
          import('./components/news/news.module').then((m) => m.NewsModule),
      },
      {
        path: 'help',
        loadChildren: () =>
          import('./components/help/help.module').then((m) => m.HelpModule),
      },
    ],
  },
  {
    path: 'terms',
    data: { type: 'terms', noButton: true },
    loadChildren: () =>
      import(
        './components/user-info/user-info-terms/user-info-terms.module'
      ).then((m) => m.UserInfoTermsModule),
  },
  {
    path: 'privacy',
    data: { type: 'privacy', noButton: true },
    loadChildren: () =>
      import(
        './components/user-info/user-info-terms/user-info-terms.module'
      ).then((m) => m.UserInfoTermsModule),
  },
  {
    path: 'register/terms',
    data: { type: 'terms' },
    loadChildren: () =>
      import(
        './components/user-info/user-info-terms/user-info-terms.module'
      ).then((m) => m.UserInfoTermsModule),
  },
  {
    path: 'register/privacy',
    data: { type: 'privacy' },
    loadChildren: () =>
      import(
        './components/user-info/user-info-terms/user-info-terms.module'
      ).then((m) => m.UserInfoTermsModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./components/register/register.module').then(
        (m) => m.RegisterModule
      ),
  },
  {
    path: 'my/setting',
    loadChildren: () =>
      import('./components/my-setting/my-setting.module').then(
        (m) => m.MySettingModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'my/setting/basic',
    loadChildren: () =>
      import(
        './components/user-info/user-info-basic/user-info-basic.module'
      ).then((m) => m.UserInfoBasicModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'my/setting/privacy',
    loadChildren: () =>
      import(
        './components/user-info/user-info-privacy/user-info-privacy.module'
      ).then((m) => m.UserInfoPrivacyModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'my/setting/booking',
  //   loadChildren: () =>
  //     import(
  //       './components/user-info/user-info-booking/user-info-booking.module'
  //     ).then((m) => m.UserInfoBookingModule),
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'my/setting/payment',
    loadChildren: () =>
      import(
        './components/user-info/user-info-payment/user-info-payment.module'
      ).then((m) => m.UserInfoPaymentModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'my/setting/subscription',
    loadChildren: () =>
      import(
        './components/user-info/user-subscription/user-subscription.module'
      ).then((m) => m.UserSubscriptionModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'my/setting/guide',
    loadChildren: () =>
      import(
        './components/user-info/user-info-guide/user-info-guide.module'
      ).then((m) => m.UserInfoGuideModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'pricing',
    loadChildren: () =>
      import('./components/pricing/pricing.module').then(
        (m) => m.PricingModule
      ),
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('./components/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./components/contact/contact.module').then(
        (m) => m.ContactModule
      ),
  },
  {
    path: 'shop',
    loadChildren: () =>
      import('./components/shop/shop.module').then((m) => m.ShopModule),
  },
  {
    path: 'terms',
    data: { type: 'terms', noButton: true },
    loadChildren: () =>
      import(
        './components/user-info/user-info-terms/user-info-terms.module'
      ).then((m) => m.UserInfoTermsModule),
  },
  {
    path: 'privacy',
    data: { type: 'privacy', noButton: true },
    loadChildren: () =>
      import(
        './components/user-info/user-info-terms/user-info-terms.module'
      ).then((m) => m.UserInfoTermsModule),
  },
  { path: '', pathMatch: 'full', redirectTo: '/m/home' },
  { path: '**', pathMatch: 'full', redirectTo: '/m/home' },
];

const routes: Routes = [
  {
    path: '',
    canActivateChild: [ScreenSizeGuard],
    children: [
      { path: '', children: desktopRouteList },
      { path: 'm', children: mobileRouteList },
    ],
  },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
