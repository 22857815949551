import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  AppService,
  BridgeService,
  PushService,
} from 'projects/pw-lib/src/public-api';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { DialogService } from '../components/dialog/dialog.service';
import { LoginComponent } from '../components/dialog/login/login.component';
import { UserApp } from '../model/user/user-app';
import { UserAppService } from '../repository/user/user-app.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private dialogService: DialogService,
    private userAppService: UserAppService,
    private pushService: PushService,
    private bridgeService: BridgeService,
    private appService: AppService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree>
    | UrlTree
    | boolean {
    const { path } = route.routeConfig;
    const returnValue = path === 'login' ? this.router.parseUrl('/my') : true;

    if (this.authService.loginInfo) {
      return returnValue;
    }

    return this.dialogService.matDialog
      .open(LoginComponent, {
        panelClass: 'fullscreen-panel',
        hasBackdrop: false,
      })
      .beforeClosed()
      .pipe(
        map((isLogin) => {
          this.saveUserApp().subscribe();
          return isLogin ? returnValue : false;
        })
      );
  }

  private saveUserApp(): Observable<UserApp> {
    return forkJoin([
      from(this.appService.version),
      from(this.pushService.pushToken),
    ]).pipe(
      mergeMap(([version, token]) => {
        const { platform } = this.bridgeService;

        if (!token) {
          return of({});
        }

        return this.userAppService.create({
          appVerNm: version,
          userAppOsType: platform === 'WEB' ? 'ETC' : platform,
          pushKey: token,
          trmnlId: '',
          userInfo: { id: this.authService.loginInfo.id },
        });
      })
    );
  }
}
