<div class="pc">
  <div class="header-wrapper" [class.scrolled]="isScrolled">
    <div class="header">
      <a class="logo" routerLink="/home" aria-label="ホームへ(ロゴ)">
        <img src="/assets/imgs/gakuyu-logo.png" />
      </a>

      <menu
        [ngClass]="{ hover: showMenu }"
        (mouseenter)="onMouseEnter()"
        (mouseleave)="onMouseLeave()"
      >
        <!-- <li>
            <a routerLink="/home">ホームへ(ロゴ)</a>
          </li> -->
        <li>
          <a
            (mouseenter)="onMouseEnter()"
            routerLink="/intro"
            routerLinkActive="on"
            (click)="onScrollIntoView($event.target)"
            >学遊ヴィレッジChoiceとは？</a
          >
        </li>
        <li>
          <a
            (mouseenter)="onMouseEnter()"
            routerLink="/lesson"
            routerLinkActive="on"
            (click)="onScrollIntoView($event.target)"
            >講座一覧</a
          >
          <!-- <app-gakuyu-submenu></app-gakuyu-submenu> -->
        </li>
        <li>
          <a
            (mouseenter)="onMouseEnter()"
            routerLink="/pricing"
            routerLinkActive="on"
            (click)="onScrollIntoView($event.target)"
            >料金システム</a
          >
        </li>
        <li>
          <a
            (mouseenter)="onMouseEnter()"
            routerLink="/faq"
            routerLinkActive="on"
            (click)="onScrollIntoView($event.target)"
            >ご利用の流れ</a
          >
        </li>
        <li>
          <a
            (mouseenter)="onMouseEnter()"
            routerLink="/contact"
            routerLinkActive="on"
            (click)="onScrollIntoView($event.target)"
            >お問い合わせ</a
          >
        </li>
        <li>
          <a
            (mouseenter)="onMouseEnter()"
            routerLink="/tokutei"
            routerLinkActive="on"
            (click)="onScrollIntoView($event.target)"
            >特定商取引法</a
          >
        </li>
        <li>
          <a
            *ngIf="!(loginInfo$ | async)"
            (click)="onScrollIntoView($event.target); onLoginClick()"
            >Login</a
          >
          <button
            mat-button
            [matMenuTriggerFor]="menu"
            *ngIf="loginInfo$ | async"
          >
            My
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item mat-ripple class="menu-item" routerLink="/my">
              私の予約
            </button>
            <button
              mat-menu-item
              mat-ripple
              (click)="onScrollIntoView($event.target); onLogoutClick()"
            >
              Logout
            </button>
          </mat-menu>
        </li>
      </menu>
    </div>
  </div>
  <div class="header-spacer"></div>
</div>
