import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gakuyu-mobile-layout',
  templateUrl: './gakuyu-mobile-layout.component.html',
  styleUrls: ['./gakuyu-mobile-layout.component.scss'],
})
export class GakuyuMobileLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
