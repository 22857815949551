<menu class="menu-list">
  <li>
    <a routerLink="lesson/">DXコース</a>
  </li>
  <li>
    <a routerLink="lesson/">語学コース</a>
  </li>
  <li>
    <a routerLink="lesson/">体操教室</a>
  </li>
  <li>
    <a routerLink="lesson/">スナッグゴルフ</a>
  </li>
</menu>
