import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Utils } from './utils';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeGuard implements CanActivateChild {
  constructor(private router: Router) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const isMobileSize = Utils.isMobileSize();
    const isMobilePath = state.url.indexOf('/m/') >= 0;

    if (isMobileSize === isMobilePath) {
      return true;
    }

    let newPath: string;

    if (isMobilePath) {
      newPath = state.url.replace('/m/', '/');
    } else {
      newPath = `/m${state.url || ''}`;
    }

    return this.router.parseUrl(newPath);
  }
}
