import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared.module';
import { GakuyuLayoutComponent } from './gakuyu-layout.component';

@NgModule({
  declarations: [GakuyuLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MatSidenavModule,
    MatExpansionModule,
  ],
  exports: [GakuyuLayoutComponent],
})
export class GakuyuLayoutModule {}
