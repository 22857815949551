<main wrapper [formGroup]="loginForm">
  <div class="close-wrap">
    <button mat-icon-button class="close-button" [mat-dialog-close]="false">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <h1 class="login-title">{{ 'login' | translate }}</h1>

  <div class="login-container">
    <app-gakuyu-form-field>
      <input
        type="text"
        placeholder="EMAIL"
        formControlName="id"
        (keyup)="onIdKeyup($event)"
      />
    </app-gakuyu-form-field>
    <app-gakuyu-form-field>
      <input
        #pwForm
        [type]="isPasswordVisible ? 'text' : 'password'"
        placeholder="PASSWORD"
        formControlName="pw"
        (keyup)="onPwKeyup($event)"
      />
      <button
        mat-icon-button
        suffix
        (click)="isPasswordVisible = !isPasswordVisible"
      >
        <mat-icon>{{
          isPasswordVisible ? 'visibility' : 'visibility_off'
        }}</mat-icon>
      </button>
    </app-gakuyu-form-field>

    <div class="sub-button-container">
      <!-- TODO: save auth -->
      <!-- <mat-checkbox formControlName="shouldSaveId">
        <mat-label>{{ 'saveId' | translate }}</mat-label>
      </mat-checkbox> -->

      <div class="empty-spacer"></div>

      <button class="register-button" (click)="onJoinClick()">
        {{ 'register' | translate }}
      </button>
    </div>

    <div class="button-wrapper">
      <button gakuyu-button (click)="onLoginClick()">
        {{ 'login' | translate }}
      </button>
    </div>

    <!-- TODO: reset password -->
    <div class="find-button" (click)="onFindClick()">
      {{ 'MSG.findLoginInfo' | translate }}
    </div>
  </div>
</main>
