import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { tap } from 'rxjs';
import { UiService } from 'src/app/core/services/ui.service';

@Component({
  selector: 'app-gakuyu-page-title',
  templateUrl: './gakuyu-page-title.component.html',
  styleUrls: ['./gakuyu-page-title.component.scss'],
})
export class GakuyuPageTitleComponent implements OnInit, AfterContentInit {
  @ViewChild('left') left: ElementRef;

  @ViewChild('right') right: ElementRef;

  /**
   * 제목
   */
  @Input() title: string;

  /**
   * 헤더 높이
   */
  @Input() height = 56;

  /**
   * 뒤로가기 버튼 여부
   */
  @Input() hasBack = true;

  /**
   * 제목 패딩 단위
   */
  @Input() titlePaddingUnit = 40;

  /**
   * 각 환경에서 컴포넌트 표시 여부
   */
  @Input() isVisible: 'both' | 'mobile' | 'desktop' = 'both';

  /**
   * 뒤로가기 클릭시
   */
  @Output() backClick = new EventEmitter();

  /**
   * 제목 패딩 단계
   */
  titlePaddingLevel;

  /**
   * 왼쪽 아이콘 개수
   */
  leftIconCnt = 0;

  /**
   * 오른쪽 아이콘 개수
   */
  rightIconCnt = 0;

  isMobile: boolean;

  get isMobileVisible(): boolean {
    return this.isVisible === 'mobile' || this.isVisible === 'both';
  }

  get isDesktopVisible(): boolean {
    return this.isVisible === 'desktop' || this.isVisible === 'both';
  }

  constructor(private uiService: UiService) {}

  ngOnInit(): void {
    this.uiService.device$
      .pipe(
        tap((device) => {
          this.isMobile = device === 'mobile';
        })
      )
      .subscribe();
  }

  ngAfterContentInit(): void {
    // 왼쪽 아이콘 개수
    this.leftIconCnt =
      (this.left?.nativeElement as HTMLElement)?.children.length || 1;
    // 오른쪽 아이콘 개수
    this.rightIconCnt =
      (this.right?.nativeElement as HTMLElement)?.children.length || 1;

    // 제목 패딩은 좌우 아이콘 개수중 높은쪽에 맞춤
    this.titlePaddingLevel = Math.max(this.leftIconCnt, this.rightIconCnt);
  }

  onBackClick(): void {
    if (this.backClick.observed) {
      this.backClick.emit();
      return;
    }

    window.history.back();
  }
}
