import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GakuyuFormFieldComponent } from './gakuyu-form-field.component';

@NgModule({
  declarations: [GakuyuFormFieldComponent],
  imports: [CommonModule],
  exports: [GakuyuFormFieldComponent],
})
export class GakuyuFormFieldModule {}
