import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { UiService } from 'src/app/core/services/ui.service';
import { AuthService } from '../../auth/auth.service';
import { ConfirmComponent } from '../dialog/confirm/confirm.component';
import { LoginComponent } from '../dialog/login/login.component';

@Component({
  selector: 'app-gakuyu-header',
  templateUrl: './gakuyu-header.component.html',
  styleUrls: ['./gakuyu-header.component.scss'],
})
export class GakuyuHeaderComponent implements OnInit {
  showMenu = false;

  isScrolled: boolean;

  loginInfo$: Observable<any>;

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private uiService: UiService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.loginInfo$ = this.auth.getLoginInfo$();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        tap(() => {
          this.showMenu = false;
        })
      )
      .subscribe();

    this.uiService.windowScrollEvent$
      .pipe(
        tap(({ scrollY }) => {
          this.isScrolled = scrollY >= 24;
        })
      )
      .subscribe();
  }

  onMouseEnter(): void {
    this.showMenu = true;
  }

  onMouseLeave(): void {
    this.showMenu = false;
  }

  onScrollIntoView(event: EventTarget): void {
    setTimeout(() => {
      (<Element>event).scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  onLoginClick(): void {
    this.matDialog
      .open(LoginComponent, { panelClass: 'fullscreen-panel' })
      .beforeClosed()
      .pipe(
        filter((isLogin) => isLogin),
        tap(() => {
          this.loginInfo$ = this.auth.getLoginInfo$();
        })
      )
      .subscribe();
  }

  onLogoutClick(): void {
    this.matDialog
      .open(ConfirmComponent, {
        data: {
          body: 'MSG.askLogout',
        },
      })
      .beforeClosed()
      .pipe(
        filter((answer) => answer === 'right'),
        tap(() => {
          this.auth.logout();
          this.loginInfo$ = null;
        })
      )
      .subscribe();
  }
}
