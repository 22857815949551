import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormFieldService {
  constructor() {}

  getErrorMessage(control: AbstractControl): string {
    if (control.hasError('required')) {
      return '必須項目に入力してください。';
    }

    if (control?.errors?.minlength) {
      return `${control.errors.minlength.requiredLength}桁以上で入力してください。`;
    }

    if (control?.errors?.maxlength) {
      return `${control.errors.maxlength.requiredLength}桁以下で入力してください。`;
    }

    if (control.hasError('match')) {
      return '一致しません。';
    }

    if (control.hasError('email')) {
      return 'メール形式が正しくありません。';
    }

    return null;
  }
}
