import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { filter, tap } from 'rxjs';
import { ISeatTimetable } from 'src/app/repository/seat-timetable/seat-timetable.model';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-seat-timetable-select',
  templateUrl: './seat-timetable-select.component.html',
  styleUrls: ['./seat-timetable-select.component.scss'],
})
export class SeatTimetableSelectComponent implements OnInit {
  seatTimetableList: ISeatTimetable[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public matDialogData: any,
    private matDialogRef: MatDialogRef<SeatTimetableSelectComponent>,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.seatTimetableList = this.matDialogData.seatTimetableList;
  }

  getTm(tm: string, min: number): string {
    return dayjs(`1111-01-01 ${tm}`).add(min, 'minute').format('HH:mm');
  }

  onNoBusClick(): void {
    this.dialogService
      .confirm('本当にバスを利用しませんか？')
      .pipe(
        filter((answer) => answer === 'right'),
        tap(() => this.matDialogRef.close({}))
      )
      .subscribe();
  }
}
