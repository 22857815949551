<h3 class="title">
  {{ matDialogData?.header | translate }}
</h3>

<div class="body">
  <div class="transport" *ngFor="let transport of transportList">
    <div class="transport-name">路線名 : {{ transport.transportNm }}</div>
    <div
      class="transport-route"
      [mat-dialog-close]="transportRoute"
      *ngFor="let transportRoute of transport.transportRouteList"
    >
      <div>搭乗時間 : {{ getTm(transportRoute.departureTm, 0) }}</div>
      <div>停留所 : {{ transportRoute.departureLocationInfo.locationNm }}</div>
      <div *ngIf="transportRoute.departureLocationInfo.address">
        住所 :{{ transportRoute.departureLocationInfo.address }}
      </div>
    </div>
  </div>
</div>

<div class="button-container" fxLayoutAlign="end">
  <button mat-flat-button class="button" (click)="onNoBusClick()">
    バスを利用しない
  </button>
  <button mat-flat-button mat-dialog-close="left" class="button">
    予約しない
  </button>
  <!-- <button mat-flat-button mat-dialog-close="left" class="button">
    {{ matDialogData?.left || 'no' | translate }}
  </button>
  <button mat-flat-button mat-dialog-close="right" class="button">
    {{ matDialogData?.right || 'ok' | translate }}
  </button> -->
</div>
