import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, Observable, throwError } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { PageRepositoryService } from '../abstract-repository.service';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService extends PageRepositoryService<any> {
  protected baseUri = 'api/userInfo';

  constructor(protected http: HttpClient, protected authService: AuthService) {
    super(http);
  }

  resetGrade(id: number): Observable<any> {
    this.isLoadingSubject.next(true);

    return this.http
      .delete(`${this.apiServerUrl}/${this.baseUri}/${id}/grade`)
      .pipe(
        finalize(() => {
          this.isLoadingSubject.next(false);
        }),
        catchError((e) =>
          throwError(() => new Error(super._getErrorMessages(e)))
        )
      );
  }
}
