import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GakuyuButtonComponent } from './gakuyu-button.component';

@NgModule({
  declarations: [GakuyuButtonComponent],
  imports: [CommonModule],
  exports: [GakuyuButtonComponent],
})
export class GakuyuButtonModule {}
