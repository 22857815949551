<h3>
  {{ matDialogData?.header | translate }}
</h3>

<p>
  {{ matDialogData?.body | translate }}
</p>

<div>
  <button gakuyu-button mat-dialog-close>
    {{ matDialogData?.ok || 'ok' | translate }}
  </button>
</div>
