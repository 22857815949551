<h3>
  {{ matDialogData?.header | translate }}
</h3>

<p>
  {{ matDialogData?.body | translate }}
</p>

<div fxLayout fxLayoutGap="12px">
  <button gakuyu-button="negative" mat-dialog-close="left">
    {{ matDialogData?.left || 'no' | translate }}
  </button>
  <button gakuyu-button mat-dialog-close="right">
    {{ matDialogData?.right || 'ok' | translate }}
  </button>
</div>
