import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { GakuyuSubmenuModule } from '../gakuyu-submenu/gakuyu-submenu.module';
import { GakuyuHeaderComponent } from './gakuyu-header.component';

@NgModule({
  declarations: [GakuyuHeaderComponent],
  imports: [
    CommonModule,
    GakuyuSubmenuModule,
    RouterModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
  ],
  exports: [GakuyuHeaderComponent, GakuyuSubmenuModule],
})
export class GakuyuHeaderModule {}
