import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { GakuyuFormFieldModule } from '../gakuyu-form-field/gakuyu-form-field.module';
import { AlertComponent } from './alert/alert.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { FindPwComponent } from './find-pw/find-pw.component';
import { LoginComponent } from './login/login.component';
import { SeatTimetableSelectComponent } from './seat-timetable-select/seat-timetable-select.component';
import { TransportRouteSelectComponent } from './transport-route-select/transport-route-select.component';

@NgModule({
  declarations: [
    AlertComponent,
    ConfirmComponent,
    LoginComponent,
    TransportRouteSelectComponent,
    SeatTimetableSelectComponent,
    FindPwComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    TranslateModule,
    GakuyuFormFieldModule,
  ],
  exports: [
    MatDialogModule,
    MatSnackBarModule,
    AlertComponent,
    ConfirmComponent,
    LoginComponent,
    FindPwComponent,
    TransportRouteSelectComponent,
  ],
})
export class DialogModule {}
