<h3 class="title">
  {{ matDialogData?.header | translate }}
</h3>

<div class="body">
  <div class="transport">
    <div
      class="transport-route"
      [mat-dialog-close]="seatTimetable"
      *ngFor="let seatTimetable of seatTimetableList"
    >
      {{ seatTimetable.startDttm | date: 'HH:mm' }} ~
      {{ seatTimetable.endDttm | date: 'HH:mm' }} |
      {{ seatTimetable.rsrvMrhstSeatCnt }}/{{ seatTimetable.maxMrhstSeatCnt }}
    </div>
  </div>
</div>

<div class="button-container" fxLayoutAlign="end">
  <button mat-flat-button mat-dialog-close="left" class="button">
    予約しない
  </button>
  <!-- <button mat-flat-button mat-dialog-close="left" class="button">
    {{ matDialogData?.left || 'no' | translate }}
  </button>
  <button mat-flat-button mat-dialog-close="right" class="button">
    {{ matDialogData?.right || 'ok' | translate }}
  </button> -->
</div>
