import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared.module';
import { GakuyuMobileLayoutComponent } from './gakuyu-mobile-layout.component';

@NgModule({
  declarations: [GakuyuMobileLayoutComponent],
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [[GakuyuMobileLayoutComponent]],
})
export class GakuyuMobileLayoutModule {}
