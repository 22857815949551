import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { AlertComponent } from './alert/alert.component';
import { ConfirmComponent } from './confirm/confirm.component';

/**
 * 머터리얼 컴포넌트를 이용한 대화창 서비스
 * @see `DialogModule`
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(public matDialog: MatDialog, public matSnackBar: MatSnackBar) {}

  /**
   * 확인 창
   * @param body 본문 내용
   * @param header 상단 내용
   * @param ok 버튼 내용
   */
  alert(body: string, header = 'alert', ok = 'ok'): Observable<any> {
    const dialogRef = this.matDialog.open(AlertComponent, {
      data: {
        body,
        header,
        ok,
      },
      disableClose: true,
      maxWidth: '90vw',
    });
    return dialogRef.beforeClosed();
  }

  /**
   * 이지선다형 창
   * @param body 본문 내용
   * @param header 상단 내용
   * @param right 오른쪽 버튼 내용
   * @param left 왼쪽 버튼 내용
   */
  confirm(
    body: string,
    header?: string,
    right = 'ok',
    left = 'no'
  ): Observable<'right' | 'left'> {
    const dialogRef = this.matDialog.open(ConfirmComponent, {
      data: {
        body,
        header,
        right,
        left,
      },
      disableClose: true,
      maxWidth: '90vw',
    });
    return dialogRef //
      .beforeClosed();
  }

  /**
   * 오류 창
   * @param body 본문 내용
   */
  error(body = 'MSG.error'): Observable<any> {
    return this.alert(body, 'error', 'ok');
  }

  /**
   * Opens a snackbar with a message and an optional action.
   * @param message The message to show in the snackbar.
   * @param action The label for the snackbar action.
   * @param config Additional configuration options for the snackbar.
   */
  snackbar(
    message: string,
    action: string = null,
    config: MatSnackBarConfig = { duration: 2000 }
  ): void {
    this.matSnackBar.open(message, action, config);
  }
}
