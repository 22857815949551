<main wrapper [formGroup]="formGroup" [class.mobile]="isMobile">
  <div>
    <div class="close-wrap">
      <button mat-icon-button class="close-button" [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <h1 class="login-title">{{ 'パスワードリセット' | translate }}</h1>

    <div class="find-container">
      <div class="email-warpper" [class.mobile]="isMobile">
        <div class="email-form">
          <app-gakuyu-form-field [hasError]="false">
            <label>EMAIL</label>
            <input
              type="text"
              placeholder="gakuyu@email.com"
              formControlName="email"
            />
          </app-gakuyu-form-field>
        </div>
        <button
          gakuyu-button
          (click)="onEmailClick($event)"
          [disabled]="isRequestPending || isSent || remainTime > 0 || isPass"
          [class.disabled]="
            isRequestPending || isSent || remainTime > 0 || isPass
          "
        >
          {{ '転送' | translate }}
        </button>
      </div>
      <span class="remain-time" *ngIf="remainTime > 0">
        {{ remainTime | date: 'm:ss' }}
      </span>

      <div class="email-warpper" [class.mobile]="isMobile">
        <div class="email-form">
          <app-gakuyu-form-field
            [disabled]="!isSent || isPass"
            [hasError]="false"
          >
            <label>認証番号</label>
            <input
              type="text"
              [readonly]="!isSent || isPass"
              placeholder="認証番号6桁入力"
              formControlName="verification"
              [maxlength]="6"
            />
          </app-gakuyu-form-field>
        </div>
        <button
          gakuyu-button
          (click)="onCertSubmit($event)"
          [disabled]="isPass || !isSent"
          [class.disabled]="isPass || !isSent"
        >
          {{ '認証' | translate }}
        </button>
      </div>
      <span class="err" *ngIf="isChkErr">{{
        '認証番号が間違っています' | translate
      }}</span>

      <app-gakuyu-form-field>
        <label>パスワード</label>
        <input
          type="password"
          autocomplete="new-password"
          formControlName="userPw"
        />
      </app-gakuyu-form-field>

      <app-gakuyu-form-field>
        <label>パスワード再確認</label>
        <input
          type="password"
          autocomplete="new-password"
          formControlName="_userPwMatch"
        />
      </app-gakuyu-form-field>
    </div>
  </div>
  <div class="desktop-button-container" [class.mobile]="isMobile">
    <button
      gakuyu-button
      [disabled]="!isPass || !formGroup.valid"
      [class.disabled]="!isPass || !formGroup.valid"
      (click)="onCickSubmit()"
    >
      {{ '確認' | translate }}
    </button>
  </div>
</main>
