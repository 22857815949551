import { AnimationEvent } from '@angular/animations';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService, StatusBarService } from 'pw-lib';
import { tap } from 'rxjs';
import { DialogService } from './components/dialog/dialog.service';
import { fadeAnimation } from './core/animation';
import { AppUpdateService } from './core/services/app-update.service';
import { RouteHelperService } from './core/services/route-helper.service';
import { UiService } from './core/services/ui.service';
import { Utils } from './core/utils';
import { LanguageService } from './services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
})
export class AppComponent implements OnInit {
  /**
   * 하드웨어 백버튼 조작시 앱 종료하는 페이지 목록
   */
  APP_EXIT_PATH_LIST = ['/m/home', '/m/my', '/m/news', '/m/help'];

  /**
   * 뒤로가기 2번 체크
   */
  shouldExitApp = false;

  /**
   * 라우팅 애니메이션 여부
   */
  shouldRouteAnimate = true;

  constructor(
    public routeHelperService: RouteHelperService,
    private activatedRoute: ActivatedRoute,
    private renderer2: Renderer2,
    private appService: AppService,
    private statusBarBrightness: StatusBarService,
    private translateService: TranslateService,
    private appUpdateService: AppUpdateService,
    private languageService: LanguageService,
    private dialogService: DialogService,
    private uiService: UiService
  ) {
    uiService.device$.subscribe();
  }

  ngOnInit(): void {
    this.uiService.device$
      .pipe(
        tap((device) => {
          if (device === 'mobile') {
            this.renderer2.addClass(document.body, 'mobile');
          } else {
            this.renderer2.removeClass(document.body, 'mobile');
          }
        })
      )
      .subscribe();
    // if (environment.production) {
    //   this.languageService.changeLang('ja');
    // } else {
    //   this.languageService.changeLang('ko');
    // }
    // this.languageService.changeLang('ja');
    this.initLanguage();
    this.appUpdateService.init();
    this.initApp();
    // this.getApiData();
  }

  onAnimationStart(e: AnimationEvent): void {
    this.routeHelperService.animationsStart(e);
  }

  /**
   * 라우팅 애니메이션 종료시
   */
  onAnimationsDone(e: AnimationEvent): void {
    this.routeHelperService.animationsDone(e);
  }

  /**
   * 앱 화면 전체를 사용할 수 있도록 설정하고, 상태바 아이콘을 밝게 설정한다
   */
  private initApp(): void {
    if (!Utils.isApp()) {
      // 앱 아니면 종료
      return;
    }

    this.renderer2.addClass(document.body, 'mobile-app');

    // SafeArea 활성화
    this.appService.useSafeArea(true);

    this.statusBarBrightness.setStatusBarBrightness('dark');

    // 기본 뒤로가기 처리 비활성화
    this.appService.useDefaultBackHandler(false);

    // 하드웨어 백버튼(안드로이드) 이벤트
    this.appService.addBackPressed(() => {
      // 열려있는 다이얼로그가 있다면 마지막 요소 닫기
      const { openDialogs } = this.dialogService.matDialog;
      if (openDialogs?.length) {
        const lastDialog = openDialogs.pop();
        lastDialog.close();
        return;
      }

      if (
        ['/', ...this.APP_EXIT_PATH_LIST].indexOf(
          this.routeHelperService.currentPath
        ) < 0
      ) {
        // 루트가 아니면 뒤로가기
        window.history.back();
        return;
      }

      if (this.shouldExitApp) {
        // 한번 더 뒤로가기 하면 종료
        this.appService.exit();
        return;
      }

      const matSnackBar = this.dialogService.matSnackBar.open(
        this.translateService.instant('MSG.askAppExit'),
        null,
        {
          // 2초간 뒤로가기 체크
          duration: 2000,
        }
      );

      // dismiss 전에 다시 누르면 종료
      matSnackBar.afterOpened().subscribe(() => {
        this.shouldExitApp = true;
      });

      // 이후면 초기화
      matSnackBar.afterDismissed().subscribe(() => {
        this.shouldExitApp = false;
      });
    });
  }

  private initLanguage(): void {
    this.activatedRoute.queryParams
      .pipe(
        tap(({ lang }) => {
          if (this.languageService.lang === lang) {
            return;
          }

          const isSupported = this.languageService.SUPPORTED_LANGUAGES.some(
            ({ value }) => value === lang
          );

          if (!isSupported) {
            return;
          }

          this.languageService.changeLang(lang);
        })
      )
      .subscribe();
  }
}
