import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

export interface ScrollEvent {
  scrollTop?: number;
  scrollLeft?: number;
  scrollHeight?: number;
  scrollWidth?: number;
}

/**
 * UI 제어를 위한 클래스
 */
@Injectable({
  providedIn: 'root',
})
export class UiService implements OnDestroy {
  destroyed = new Subject<void>();

  // TODO: s ~ m, m ~ l 사이에 들어가는 크기 대응
  displayNameMap = new Map([
    // ['(min-aspect-ratio: 9/12)', 's'],
    // ['(min-height: 480px) and (min-aspect-ratio: 9/16)', 'm'],
    // ['(max-aspect-ratio: 9/16) and (min-aspect-ratio: 9/20)', 'm'],
    // ['(min-height: 640px) and (min-aspect-ratio: 9/20)', 'l'],
    // ['(max-height: 1280px) and (max-aspect-ratio: 9/20)', 'l'],
    ['(max-width: 768px)', 'mobile'],
  ]);

  /**
   * app-root의 스크롤 이벤트 서브젝트
   */
  scrollEvent$: BehaviorSubject<ScrollEvent> = new BehaviorSubject({});

  /** 모바일 구분해서 표시할 때 사용 */
  device$: Observable<string>;

  windowScrollEvent$: BehaviorSubject<Window> = new BehaviorSubject(window);

  constructor(private breakpointObserver: BreakpointObserver) {
    // 모바일 기기 기준
    this.device$ = this.breakpointObserver
      .observe(Array.from(this.displayNameMap.keys()))
      .pipe(
        takeUntil(this.destroyed),
        map((r) => {
          let result = 'pc';
          Object.keys(r.breakpoints).forEach((query) => {
            if (r.breakpoints[query]) {
              result = this.displayNameMap.get(query) ?? 'Unknown';
            }
          });

          return result;
        }),
        shareReplay()
      );

    window.addEventListener('scroll', () => {
      this.windowScrollEvent$.next(window);
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
