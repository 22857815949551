import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { CertCheckModel } from 'src/app/model/cert/cert-check.model';
import { CertRequestModel } from 'src/app/model/cert/cert-request.model';
import { CertResponse } from 'src/app/model/cert/cert-response.model';
import { CertValidateModel } from 'src/app/model/cert/cert-validate.model';
import { Cert } from 'src/app/model/cert/cert.model';
import { environment } from 'src/environments/environment';
import { PageRepositoryService } from '../abstract-repository.service';

@Injectable({
  providedIn: 'root',
})
export class CertService extends PageRepositoryService<Cert> {
  protected baseUri = 'api/cert';

  serverUrl = environment.apiServerUrl;

  constructor(protected http: HttpClient, protected authService: AuthService) {
    super(http);
  }

  requestCert(
    type: 'phone' | 'email',
    data: CertRequestModel
  ): Observable<CertResponse> {
    this.isLoadingSubject.next(true);

    return this.http
      .post<CertResponse>(
        `${this.apiServerUrl}/${this.baseUri}/${type}/req`,
        data
      )
      .pipe(
        tap(() => {
          this.isLoadingSubject.next(false);
        }),
        this.handleError()
      );
  }

  checkCert(
    type: 'phone' | 'email',
    data: CertCheckModel
  ): Observable<CertResponse> {
    this.isLoadingSubject.next(true);

    return this.http
      .post<CertResponse>(
        `${this.apiServerUrl}/${this.baseUri}/${type}/chk`,
        data
      )
      .pipe(
        tap(() => {
          this.isLoadingSubject.next(false);
        }),
        this.handleError()
      );
  }

  checkCertEncrypted(data: string): Observable<CertResponse> {
    this.isLoadingSubject.next(true);

    return this.http
      .post<CertResponse>(`${this.apiServerUrl}/${this.baseUri}/chk`, data)
      .pipe(
        tap(() => {
          this.isLoadingSubject.next(false);
        }),
        this.handleError()
      );
  }

  valid(data: CertValidateModel): Observable<boolean> {
    this.isLoadingSubject.next(true);

    return this.http
      .post<boolean>(`${this.apiServerUrl}/${this.baseUri}/valid`, data)
      .pipe(
        tap(() => {
          this.isLoadingSubject.next(false);
        }),
        this.handleError()
      );
  }
}
