import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gakuyu-footer',
  templateUrl: './gakuyu-footer.component.html',
  styleUrls: ['./gakuyu-footer.component.scss'],
})
export class GakuyuFooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  onTwitterClick(): void {
    window.location.assign('https://twitter.com/choice_FLC');
  }

  onInstagramClick(): void {
    window.location.assign('https://www.instagram.com/flc.choice/?hl=ja');
  }

  onYoutubeClick(): void {
    window.location.assign(
      'https://www.youtube.com/channel/UCM0tj8H6hxQx43V7yA3hYlA'
    );
  }
}
