import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, Observable, tap } from 'rxjs';
import { UiService } from 'src/app/core/services/ui.service';
import { AuthService } from '../../auth/auth.service';
import { ConfirmComponent } from '../dialog/confirm/confirm.component';
import { LoginComponent } from '../dialog/login/login.component';

@Component({
  selector: 'app-gakuyu-layout',
  templateUrl: './gakuyu-layout.component.html',
  styleUrls: ['./gakuyu-layout.component.scss'],
})
export class GakuyuLayoutComponent implements OnInit {
  isMobile: boolean;

  isScrolled: boolean;

  isOpened = false;

  loginInfo$: Observable<any>;

  constructor(
    private matDialog: MatDialog,
    private uiService: UiService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loginInfo$ = this.authService.getLoginInfo$();
    this.uiService.device$
      .pipe(
        tap((device) => {
          this.isMobile = device === 'mobile';
        })
      )
      .subscribe();

    this.uiService.windowScrollEvent$
      .pipe(
        tap(({ scrollY }) => {
          this.isScrolled = scrollY >= 24;
        })
      )
      .subscribe();
  }

  onOpen(): void {
    this.isOpened = true;
  }

  onClose(): void {
    this.isOpened = false;
  }

  onLoginClick(): void {
    this.matDialog
      .open(LoginComponent)
      .beforeClosed()
      .pipe(
        filter((isLogin) => isLogin),
        tap(() => {
          this.loginInfo$ = this.authService.getLoginInfo$();
        })
      )
      .subscribe();
  }

  onLogoutClick(): void {
    this.matDialog
      .open(ConfirmComponent, {
        data: {
          body: 'MSG.askLogout',
        },
      })
      .beforeClosed()
      .pipe(
        filter((answer) => answer === 'right'),
        tap(() => {
          this.authService.logout();
          this.loginInfo$ = null;
        })
      )
      .subscribe();
  }

  onScrollTopClick(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onTwitterClick(): void {
    window.location.assign('https://twitter.com/choice_FLC');
  }

  onInstagramClick(): void {
    window.location.assign('https://www.instagram.com/flc.choice/?hl=ja');
  }

  onYoutubeClick(): void {
    window.location.assign(
      'https://www.youtube.com/channel/UCM0tj8H6hxQx43V7yA3hYlA'
    );
  }
}
