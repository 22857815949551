<router-outlet></router-outlet>
<!-- <div
  [@routeAnimations]="
    shouldRouteAnimate && routeHelperService.routeDirection$ | async
  "
  (@routeAnimations.start)="onAnimationStart($event)"
  (@routeAnimations.done)="onAnimationsDone($event)"
>
  <router-outlet></router-outlet>
</div> -->
