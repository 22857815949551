import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  NgControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { FormFieldService } from './form-field.service';

export const matchValidator = (matchTo: string): ValidatorFn | null => {
  return (control: AbstractControl): ValidationErrors | null => {
    return control?.parent?.get(matchTo)?.value === control?.value
      ? null
      : { match: true };
  };
};

@Component({
  selector: 'app-gakuyu-form-field',
  templateUrl: './gakuyu-form-field.component.html',
  styleUrls: ['./gakuyu-form-field.component.scss'],
  host: {
    '[class.focused]': 'focused',
  },
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GakuyuFormFieldComponent
  implements OnInit, AfterContentInit, AfterViewInit
{
  @Input() disabled = false;

  @ViewChild('label')
  private label: ElementRef<HTMLDivElement>;

  @ViewChild('infix')
  private infix: ElementRef<HTMLDivElement>;

  @Input() hasError = true;

  @ContentChild(NgControl)
  private ngControl: NgControl;

  get control(): FormControl {
    return <FormControl>this.ngControl?.control;
  }

  input: HTMLInputElement | HTMLTextAreaElement;

  focused = false;

  constructor(private formFieldService: FormFieldService) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {}

  ngAfterViewInit(): void {
    this.input =
      this.infix?.nativeElement?.querySelector('input') ||
      this.infix?.nativeElement?.querySelector('textarea');

    if (this.input) {
      this.input.onfocus = () => {
        this.focused = true;
      };

      this.input.onblur = () => {
        this.focused = false;
      };
    }
  }

  getErrorMessage(): string {
    if (!this.hasError) {
      return null;
    }

    return this.formFieldService.getErrorMessage(this.control);
  }
}
