<router-outlet></router-outlet>
<nav matRipple [matRippleRadius]="40" class="mobile-nav">
  <button class="mobile-nav-button" routerLinkActive="on" routerLink="/m/home">
    会員証
  </button>
  <button class="mobile-nav-button" routerLinkActive="on" routerLink="/m/my">
    マイページ
  </button>
  <button class="mobile-nav-button" routerLinkActive="on" routerLink="/m/news">
    お知らせ
  </button>
  <button class="mobile-nav-button" routerLinkActive="on" routerLink="/m/help">
    ヘルプ&<br />サポート
  </button>
</nav>
<div class="mobile-nav-spacer"></div>
