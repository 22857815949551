import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from './dialog/dialog.module';
import { GakuyuButtonModule } from './gakuyu-button/gakuyu-button.module';
import { GakuyuFixedButtonContainerModule } from './gakuyu-fixed-button-container/gakuyu-fixed-button-container.module';
import { GakuyuFooterModule } from './gakuyu-footer/gakuyu-footer.module';
import { GakuyuFormFieldModule } from './gakuyu-form-field/gakuyu-form-field.module';
import { GakuyuHeaderModule } from './gakuyu-header/gakuyu-header.module';
import { GakuyuPageTitleModule } from './gakuyu-page-title/gakuyu-page-title.module';

const modules = [
  CommonModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  MatIconModule,
  MatButtonModule,
  MatRippleModule,
  TranslateModule,
  DialogModule,
  GakuyuButtonModule,
  GakuyuFooterModule,
  GakuyuFormFieldModule,
  GakuyuHeaderModule,
  GakuyuPageTitleModule,
  GakuyuFixedButtonContainerModule,
];

/**
 * 공통으로 사용되는 모듈 모음
 */
@NgModule({
  imports: modules,
  exports: modules,
})
export class SharedModule {}
