import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { UserInfoService } from './user-info.service';

@Injectable({
  providedIn: 'root',
})
export class UserPwChangeService extends UserInfoService {
  protected baseUri = 'api/userInfo/updatePw';

  constructor(protected http: HttpClient, protected authService: AuthService) {
    super(http, authService);
  }

  updatePw(id: number, body: { userPw: string }): Observable<any> {
    return this.http.put(`${this.apiServerUrl}/${this.baseUri}/${id}`, body);
  }
}
