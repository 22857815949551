<div #label class="label-wrapper">
  <ng-content select="label"></ng-content>
</div>
<label class="input-wrapper" [class.disabled]="disabled">
  <div class="prefix">
    <ng-content select="[prefix]"></ng-content>
  </div>
  <div class="infix" #infix>
    <ng-content></ng-content>
  </div>
  <div class="suffix">
    <ng-content select="[suffix]"></ng-content>
  </div>
</label>

<ng-container *ngIf="control?.touched && hasError">
  <label error *ngIf="getErrorMessage() as errorMessage">
    {{ errorMessage }}
  </label>
</ng-container>
