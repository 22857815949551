import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  /**
   * 현재 선택한 언어
   */
  #lang: string;

  /**
   * 현재 선택한 언어가 없다면 스토리지 > 브라우저 기본 값 중 표시 언어, en 순으로 확인하며 가져온다
   */
  get lang(): string {
    if (this.#lang) {
      return this.#lang;
    }

    this.#lang = localStorage.getItem('lang') ?? (navigator.language || 'en');
    if (!this.SUPPORTED_LANGUAGES.find((item) => item.value === this.#lang)) {
      // 지원언어에 선택한 언어가 없다면 'en' 선택
      const [lang] = this.SUPPORTED_LANGUAGES;
      this.#lang = lang?.value || 'en';
    }
    this.translateService.use(this.#lang);
    return this.#lang;
  }

  get currentLocale(): { name: string; value: string } {
    return this.SUPPORTED_LANGUAGES.find((item) => item.value === this.lang);
  }

  /**
   * 현재 지원하는 언어
   */
  SUPPORTED_LANGUAGES = [
    { name: '日本語', value: 'ja' },
    { name: '한국어', value: 'ko' },
    { name: 'English', value: 'en' },
  ];

  constructor(private translateService: TranslateService) {}

  /** 표시 언어 상태를 변경하고 스토리지에 저장 */
  changeLang(lang: string): void {
    this.#lang = lang;
    this.translateService.use(lang);
    localStorage.setItem('lang', lang);
  }
}
