import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gakuyu-submenu',
  templateUrl: './gakuyu-submenu.component.html',
  styleUrls: ['./gakuyu-submenu.component.scss']
})
export class GakuyuSubmenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
