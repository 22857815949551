import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { StorageService } from 'projects/pw-lib/src/public-api';
import { tap } from 'rxjs';
import { UiService } from 'src/app/core/services/ui.service';
import { CertService } from 'src/app/repository/cert/cert.service';
import { UserInfoCheckExistService } from 'src/app/repository/user/user-info-check-exist.service';
import { UserPwChangeService } from 'src/app/repository/user/user-pw-change.service';
import { matchValidator } from '../../gakuyu-form-field/gakuyu-form-field.component';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-find-pw',
  templateUrl: './find-pw.component.html',
  styleUrls: ['./find-pw.component.scss'],
})
export class FindPwComponent implements OnInit {
  formGroup: FormGroup = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]],
    userPw: [
      null,
      [Validators.required, Validators.minLength(8), Validators.maxLength(20)],
    ],
    _userPwMatch: [null, [Validators.required, matchValidator('userPw')]],
    verification: [null, Validators.maxLength(6)],
  });

  BRAND_CD = 'gakuyu';

  CORP_NO = '0';

  interval: any;

  isMobile: boolean;

  remainTimeTo = 0;

  isRequestPending = false;

  isSent = false;

  certId: number;

  remainTime = 0;

  isPass = false;

  userId: number;

  isChkErr = false;

  constructor(
    private certService: CertService,
    private storageService: StorageService,
    protected dialogService: DialogService,
    private matDialogRef: MatDialogRef<FindPwComponent>,
    private formBuilder: FormBuilder,
    private userPwChangeService: UserPwChangeService,
    private userInfoCheckExistService: UserInfoCheckExistService,
    private uiService: UiService
  ) {}

  ngOnInit(): void {
    this.uiService.device$
      .pipe(
        tap((device) => {
          this.isMobile = device === 'mobile';
        })
      )
      .subscribe();
    const time = this.storageService.get('FIND_CERT_REMAIN_TO', 0);
    const email = this.storageService.get('FIND_CERT_EMAIL');
    this.certId = this.storageService.get('FIND_CERT_ID');
    this.userId = this.storageService.get('FIND_USER_ID');
    if (time || email) {
      this.remainTimeTo = time;
      this.initInterval();
      this.formGroup.patchValue({ email });
      this.isSent = true;
    }
    this.formGroup.controls.email.valueChanges.subscribe(() => {
      this.remainTime = 0;
      this.remainTimeTo = 0;
      this.storageService.delete('FIND_CERT_REMAIN_TO');
      this.storageService.delete('FIND_CERT_EMAIL');
      this.storageService.delete('FIND_CERT_ID');
      this.storageService.delete('FIND_USER_ID');
      this.isRequestPending = false;
      this.isPass = false;
      this.isSent = false;
      this.isChkErr = false;

      if (this.formGroup.value.verification)
        this.formGroup.patchValue({ verification: '' });
    });
  }

  onCertSubmit(event: Event): void {
    event.preventDefault();
    this.isRequestPending = true;
    if (this.formGroup.get('verification').valid) {
      this.certService
        .checkCert('email', {
          brandCd: this.BRAND_CD,
          corpNo: this.CORP_NO,
          receiver: this.formGroup.get('email').value,
          certId: this.certId,
          certNum: this.formGroup.get('verification').value,
        })
        .subscribe((result) => {
          if (result.result) {
            this.remainTimeTo = 0;

            this.storageService.delete('FIND_CERT_REMAIN_TO');
            this.storageService.delete('FIND_CERT_EMAIL');
            this.storageService.delete('FIND_CERT_ID');
            this.storageService.delete('FIND_USER_ID');
            this.isPass = true;
            this.isSent = false;
            this.isChkErr = false;
          } else {
            this.isChkErr = true;
          }
        });
    } else {
      this.isChkErr = true;
    }
  }

  onEmailClick(e: any): void {
    e.preventDefault();
    this.isRequestPending = true;
    if (this.formGroup.get('email').valid) {
      this.certService
        .requestCert('email', {
          brandCd: this.BRAND_CD,
          corpNo: this.CORP_NO,
          receiver: this.formGroup.get('email').value,
          reqType: 'CODE',
        })
        .subscribe((res) => {
          const minute = Math.min(res.valid / 60);
          this.remainTimeTo = Date.now() + minute * 60 * 1000;
          this.storageService.set('FIND_CERT_REMAIN_TO', this.remainTimeTo);
          this.storageService.set(
            'FIND_CERT_EMAIL',
            this.formGroup.get('email').value
          );
          this.storageService.set('FIND_USER_ID', this.userId);
          this.certId = res.certId;
          this.storageService.set('FIND_CERT_ID', this.certId);
          this.initInterval();
          this.dialogService.alert(
            '入力したアドレスに認証メールが送信されました。メールの番号を入力するとログイン情報のリセットを進めることができます。番号は10分後に期限切れになります。',
            'お知らせ'
          );
          this.isSent = true;
          this.isRequestPending = false;
        });
    } else {
      this.dialogService.alert('メールフォームをご確認ください。', '');
      this.isRequestPending = false;
    }
  }

  private initInterval(): void {
    this.remainTime = this.remainTimeTo - Date.now();
    if (this.remainTime <= 0) {
      this.storageService.delete('FIND_CERT_REMAIN_TO');
      this.storageService.delete('FIND_CERT_EMAIL');
      this.storageService.delete('FIND_CERT_ID');
      this.storageService.delete('FIND_USER_ID');

      this.certId = null;
      return;
    }
    this.interval = setInterval(() => {
      this.remainTime = this.remainTimeTo - Date.now();
      if (this.remainTime <= 0) {
        this.storageService.delete('FIND_CERT_REMAIN_TO');
        this.storageService.delete('FIND_CERT_EMAIL');
        this.storageService.delete('FIND_CERT_ID');
        this.storageService.delete('FIND_USER_ID');
        this.certId = null;
        this.isSent = false;
        clearInterval(this.interval);
      }
    }, 1000);
  }

  onCickSubmit(): void {
    if (this.isPass) {
      this.userInfoCheckExistService
        .checkExist({
          email: this.formGroup.get('email').value,
        })
        .subscribe((user) => {
          if (user.userInfoIdList.length) {
            this.userId = user.userInfoIdList[0] ?? 0;
            this.userPwChangeService
              .updatePw(this.userId, {
                userPw: this.formGroup.value.userPw,
              })
              .subscribe(() => {
                this.matDialogRef.close();
              });
          } else {
            this.dialogService.alert('存在しないメンバーです', '');
            this.isRequestPending = false;
          }
        });
    }
  }
}
